.attachfile .link_input_container {
    width: 100%;
}
.SD_video_wrapper .react-player {width: 100%;height: 100%;overflow: hidden;position: relative;padding-top: 56.25%;}
.SD_video_wrapper .react-player .react-player__preview, .SD_video_wrapper .react-player iframe {position: absolute;
    left: 0;
    top: 0;width: 100%;
    height: 100%;border: 2px solid #fff;}
.header_top_desc {display: block;font-size: 18px;line-height: 24px;padding: 10px;background: #dfdfdf;}
.header_top_heading {background: #198bd0;text-align: center;color: #fff;text-transform: capitalize;font-size: 30px;padding: 10px;border: 5px solid #1b3787;}
.main_video_wraper {width: 1400px;margin: 0 auto;}
.main_video_wraper .header_portion_information_video {padding: 20px;background: #198bd0;margin: 30px 0;box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;}
.bottom_test_desc_wrp {background: #126ca9;padding: 20px;}
.bottom_test_desc_wrp h2 {font-size: 30px;line-height: 36px;color: #fff;font-weight: 600;margin: 10px 0;}
.bottom_test_desc_wrp span {font-size: 18px;line-height: 24px;color: #fff;font-weight: 500;}
.header_portion_information {margin-top: 25px;}

.attachments_modal {height: 600px;
    overflow-y: scroll;margin-top: 20px;}
.SD_large_modal {width: 800px;}
.InformationAddEdit .formInline .custom_btn .data_container .data_set {
    width: calc(85% + 14px);
}
.InformationAddEdit .formInline .custom_btn .data_container .data_set video {width: 100%;}
.InformationAddEdit .custom_btn .data_btn_container {margin-left: 30px;}
.InformationAddEdit .formInline .custom_btn .data_container {width: auto;padding: 15px;}
.InformationAddEdit .data_set iframe {width: 100%;height: 400px;}
.InformationAddEdit .data_set .docshowifram {width: 100%;height: 400px;}
.InformationAddEdit .data_set p {margin: 10px 0;}
.InformationAddEdit .data_set img {width: 100%;display: block;}
.InformationAddEdit .custom_btn .data_block {margin:10px 0;}
.InformationAddEdit .data_set audio {width: 90%;}



@media only screen and (max-width: 1599px) {
.main_video_wraper {width: 94%;}
}
@media only screen and (max-width: 1199px) {
.header_portion_information {margin-top: 0px;}
.main_video_wraper {width: auto;}
}
@media only screen and (max-width: 799px) {
.main_video_wraper .header_portion_information_video {padding: 10px;}
.bottom_test_desc_wrp {padding: 15px;}
.header_top_heading {font-size: 25px;line-height: 31px;}
}
@media only screen and (max-width: 523px) {
    .InformationAddEdit .formInline .custom_btn .data_container .data_set {
        width: auto;
    }


}
@media only screen and (max-width: 499px) {
.bottom_test_desc_wrp h2 {font-size: 22px;line-height: 28px;}
.bottom_test_desc_wrp span {font-size: 16px;line-height: 22px;}

}

